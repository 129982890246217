import IconButton from '../IconButton';
import Typography from '../Typography';
import { tannerGray } from '../ThemeProvider/colors';
import { styled } from '@mui/material';
import Button from '../Button';
import { ArrowLeft } from '@octanner/prism-icons';

export const StyledButton = styled(Button)({
  color: tannerGray[700],
  '&:hover': {
    backgroundColor: tannerGray[200],
  },
  '&:focus': {
    backgroundColor: tannerGray[200],
  },
  '&.Mui-disabled': {
    color: tannerGray[700],
  },
});

export const StyledBackButton = styled(StyledButton)({
  paddingLeft: 9,
  '&:focus': {
    textDecoration: 'underline',
  },
  '&:active': {
    textDecoration: 'none',
  },
});

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'upperShadow',
})<React.HTMLAttributes<HTMLDivElement> & { upperShadow: boolean }>(
  ({ upperShadow = false }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    boxShadow: upperShadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
  }),
);

export const StyledNoBackTextDiv = styled('div')({
  height: 40,
  width: 96,
});

export const StyledNoBackTextDivMobile = styled('div')({
  height: 40,
  width: 40,
});

export const StyledDefaultBackText = styled('div')({
  paddingTop: 1,
});

export const StyledTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 0,
});

export const StyledIconButton = styled(IconButton)({
  color: tannerGray['700'],
  '&:focus': {
    ':focus-visible': {
      backgroundColor: tannerGray['200'],
    },
  },
  '&:hover': {
    color: tannerGray['700'],
    backgroundColor: tannerGray['200'],
  },
});

export const StyledArrowLeft = styled(ArrowLeft)({
  marginBottom: 1,
  marginRight: -4,
});
