import React from 'react';
import '@date-io/luxon'; // This is only needed to satisfy types on build
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import {
  LocalizationProvider,
  LocalizationProviderProps,
} from '@mui/x-date-pickers/LocalizationProvider';
import { StyledTextField, PaperStyles, InputAdornmentStyles } from './styles';
import { ArrowDown, ArrowLeft, ArrowRight } from '@octanner/prism-icons';

type Props<TInputDate, TDate = TInputDate> = LocalizationProviderProps &
  Omit<DatePickerProps<TInputDate, TDate>, 'renderInput'> &
  Partial<Pick<DatePickerProps<TInputDate, TDate>, 'renderInput'>>;

const DatePicker = <TInputDate, TDate = TInputDate>({
  dateAdapter,
  dateFormats,
  dateLibInstance,
  localeText,
  adapterLocale,
  locale,
  renderInput,
  ...props
 }: Props<TInputDate, TDate>) => {
  const { t } = useTranslation();

  return (
  <LocalizationProvider
    dateAdapter={dateAdapter}
    adapterLocale={adapterLocale || locale || 'en-US'}
    dateFormats={dateFormats}
    dateLibInstance={dateLibInstance}
    localeText={localeText}
  >
    <MuiDatePicker
      inputFormat="dd MMM yyyy"
      renderInput={
        renderInput ||
        ((params) => (
          <StyledTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              readOnly: props.readOnly,
            }}
            inputProps={{
              ...params.inputProps,
              placeholder: t('datepicker-placeholder', 'DD MMM YYYY'),
            }}
            helperText={t(
              'datepicker-helper-text',
              'Enter date in DD MMM YYYY format',
            )}
          />
        ))
      }
      components={{
        SwitchViewIcon: ArrowDown,
        LeftArrowIcon: ArrowLeft,
        RightArrowIcon: ArrowRight,
      }}
      InputAdornmentProps={{
        sx: InputAdornmentStyles,
      }}
      PaperProps={{
        sx: PaperStyles,
      }}
      {...props}
    />
  </LocalizationProvider>
);
};
export default DatePicker;
