import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '../Button';
import { MenuCategoriesProps, Props } from './types';
import {
  ButtonText,
  FullWidthDivider,
  GroupHeader,
  GroupHeaderText,
  GroupItems,
  MenuItemSecondary,
  StyledMenuItem,
  StyledMenuItemLink,
} from './styles';
import { Menu, Typography } from '@mui/material';

const MenuItemAll: React.FC<{
  menuItem: Props;
  'aria-label'?: string;
  'data-testid'?: string;
  handleNavigation?: (url: string) => (e: React.MouseEvent) => void;
}> = ({
  menuItem: { url, primary, secondary },
  'aria-label': ariaLabel,
  'data-testid': testid,
  handleNavigation,
}) =>
  url ? (
    <StyledMenuItemLink
      aria-label={`${ariaLabel} link`}
      key={primary}
      href={url}
      target="_blank"
      onClick={handleNavigation && handleNavigation(url)}
      data-testid={`${testid}-link`}
    >
      <MenuItemPrimarySecondary primary={primary} secondary={secondary} />
    </StyledMenuItemLink>
  ) : (
    <StyledMenuItem
      aria-label={ariaLabel}
      key={primary}
      disableRipple
      data-testid={`${testid}-item`}
    >
      <MenuItemPrimarySecondary primary={primary} secondary={secondary} />
    </StyledMenuItem>
  );

const MenuItemPrimarySecondary: React.FC<Props> = ({ primary, secondary }) => (
  <>
    <Typography>{primary}</Typography>
    <MenuItemSecondary>{secondary}</MenuItemSecondary>
  </>
);

const CategoricMenu: React.FC<MenuCategoriesProps> = ({
  'aria-label': ariaLabel,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  buttonText,
  buttonVariant = 'contained',
  buttonSx,
  'data-testid': dataTestId,
  handleNavigationClick,
  options,
  sort,
  sortOrder,
  style,
  sx,
  transformOrigin = { vertical: 'top', horizontal: 'left' },
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const grouped = _.mapValues(_.groupBy(options, 'category'), (glist) =>
    glist.map((item) => _.omit(item, 'category')),
  );

  const order = Object.keys(grouped);

  if (sort && !sortOrder) {
    order.sort();
  }

  const groupedArray = Object.entries(grouped).map((item) => ({
    [item[0]]: item[1],
  }));

  const groupedSortedArray = _.sortBy(
    groupedArray,
    sortOrder ? sortOrder : order,
  );
  let menuItems: NodeListOf<HTMLElement> | null = null;

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          menuItems = document.querySelectorAll('[role="menuitem"]');
          menuItems[0]?.focus();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    return () => {
      observer.disconnect();
    };
  }, [Boolean(anchorEl)]);

  let currentIndex = 0;
  const handleKeyboardNavigation = (e: React.KeyboardEvent) => {
    if (anchorEl && menuItems && menuItems.length > 0) {
      let newFocus: HTMLElement | null = null;
      switch (e.key) {
        case 'ArrowUp':
          if (currentIndex === 0) {
            currentIndex = menuItems.length;
          }
          currentIndex--;
          newFocus = menuItems[currentIndex] as HTMLElement;
          break;
        case 'ArrowDown':
          if (currentIndex === menuItems.length - 1) {
            currentIndex = -1;
          }
          currentIndex++;
          newFocus = menuItems[currentIndex] as HTMLElement;
          break;
      }
      if (newFocus) {
        newFocus.focus();
      }
    }
  };

  return (
    <div data-testid={dataTestId}>
      <Button
        aria-expanded={!!anchorEl}
        aria-haspopup="true"
        aria-label={ariaLabel}
        data-testid={`${dataTestId}-button`}
        disableFocusRipple
        disableRipple
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={buttonSx}
        variant={buttonVariant}
      >
        <ButtonText>{buttonText}</ButtonText>
      </Button>
      <Menu
        aria-label={ariaLabel}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        onKeyDown={handleKeyboardNavigation}
        onClose={() => {
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        slotProps={{
          paper: {
            sx,
            style,
          },
        }}
        data-testid={`${dataTestId}-menu`}
        transformOrigin={transformOrigin}
      >
        {groupedSortedArray.map((value, index) => (
          <li
            role="group"
            aria-labelledby={`${Object.keys(value)[0]}`}
            key={Object.keys(value)[0]}
          >
            <GroupHeader role="presentation" id={`${Object.keys(value)[0]}`}>
              <GroupHeaderText>{Object.keys(value)[0]}</GroupHeaderText>
            </GroupHeader>
            <GroupItems
              aria-label={`${Object.keys(value)[0]}`}
              key={`${Object.keys(value)[0]}-groupitems`}
              role="menu"
            >
              {Object.values(value)[0].map((item) => (
                <MenuItemAll
                  aria-label={ariaLabel}
                  data-testid={`${dataTestId}-${item.primary}`}
                  menuItem={item}
                  handleNavigation={handleNavigationClick}
                  key={`${item.primary} ${item.secondary}`}
                />
              ))}
            </GroupItems>
            {index !== groupedSortedArray.length - 1 && (
              <FullWidthDivider role="separator" aria-hidden={true} />
            )}
          </li>
        ))}
      </Menu>
    </div>
  );
};

export default CategoricMenu;
