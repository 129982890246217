import React from 'react';
import { styled } from '@mui/material';
import { ArrowDown } from '@octanner/prism-icons';

import { tannerSemantic } from '../ThemeProvider/colors';
import themeOptions from '../ThemeProvider/defaultTheme';

type CustomColor = 'success' | 'info' | 'warning' | 'error' | 'archived';

interface ITag {
  open?: boolean;
  hasMenu?: boolean;
  variant?: CustomColor;
}

const StyledTag = styled('button', {
  shouldForwardProp: (prop) => !/open|variant|hasMenu/.test(String(prop)),
})<ITag>(({ open = false, variant = 'info', hasMenu = false }) => ({
  alignItems: 'center',
  backgroundColor: tannerSemantic[variant].background,
  border: 'none',
  borderRadius: 3,
  cursor: hasMenu ? 'pointer' : '',
  color: tannerSemantic[variant].color,
  display: 'inline-flex',
  fontSize: 14,
  lineHeight: '140%',
  padding: '2px 8px',
  minHeight: '24px',
  fontWeight: '500',
  fontFamily:
    themeOptions &&
    themeOptions['typography'] &&
    themeOptions['typography']['fontFamily'],

  '& > svg': {
    marginLeft: 4,
    marginRight: -2,
    transition: 'transform 200ms',
    transform: open ? 'rotate3d(0, 0, 1, -180deg)' : '',
  },
}));

const Tag: React.FC<
  React.HTMLAttributes<HTMLButtonElement> & ITag & { hasMenu?: boolean }
> = ({ children, hasMenu = false, ...props }) => (
  <StyledTag {...props} type="button" disabled={!hasMenu} hasMenu={hasMenu}>
    {children}
    {hasMenu && <ArrowDown fill="currentColor" />}
  </StyledTag>
);

export default Tag;
