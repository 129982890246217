import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import React from 'react';
import classNames from 'classnames';

interface AvatarProps extends MuiAvatarProps {
  alt?: string;
  size: Size;
  src?: string;
}

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const usePixelSize = (size: Size) => {
  switch (size) {
    case 'xs':
      return {
        diameter: 24,
        fontSize: 14,
      };
    case 'sm':
      return {
        diameter: 32,
        fontSize: 16,
      };
    case 'md':
      return {
        diameter: 40,
        fontSize: 18,
      };
    case 'lg':
      return {
        diameter: 72,
        fontSize: 32,
      };
    case 'xl':
      return {
        diameter: 120,
        fontSize: 48,
      };
  }
};

const Avatar: React.FC<AvatarProps> = ({
  alt,
  size = 'xs',
  src,
  style,
  className,
  children,
  ...props
}) => {
  const { diameter, fontSize } = usePixelSize(size);
  return (
    <MuiAvatar
      alt={alt}
      src={src}
      className={classNames(className, 'fs-exclude')}
      style={{
        width: diameter,
        height: diameter,
        fontSize,
        ...style,
      }}
      {...props}
    >
      {React.Children.count(children) > 0
        ? children
        : !src && alt
        ? alt[0]
        : null}
    </MuiAvatar>
  );
};

export default Avatar;
