import * as React from 'react';
import { MenuItemProps } from '@mui/material/MenuItem';
import { Check } from '@octanner/prism-icons';
import { StyledListItemIcon, StyledMenuItemBase } from './styles';
export * from '@mui/material/MenuItem';

const MenuItem: React.FC<
  Omit<MenuItemProps, 'button'> & {
    variant?: 'normal' | 'check';
  }
> = (props) => {
  return (
    <StyledMenuItemBase {...props}>
      {props.variant === 'check' && (
        <StyledListItemIcon>{props.selected && <Check />}</StyledListItemIcon>
      )}
      {props.children}
    </StyledMenuItemBase>
  );
};

export default MenuItem;
