import { styled } from '@mui/material/styles';
import { tannerGray, tannerBlack } from '../ThemeProvider/colors';
import TextField from '../TextField';

export const StyledTextField = styled(TextField)({
  '> div': {
    input: {
      paddingRight: '28px',
    },
  },
    svg: {
      transition: 'color .2s',
    },
  '&:hover, &:focus-within': {
    svg: {
      color: tannerBlack,
    },
  },
});

export const InputAdornmentStyles = {
  svg: {
    color: tannerGray['600'],
  },
};

export const PaperStyles = {
  marginTop: '4px',
  '& .MuiPickersCalendarHeader-root, .MuiIconButton-root .MuiSvgIcon-root': {
    color: tannerGray['600'],
  },
  '& .MuiPickersCalendarHeader-label': {
    marginLeft: '6px',
    marginRight: '2px',
  },
  '& .MuiPickersArrowSwitcher-root': {
    fontSize: '1rem',
  },
  '& .MuiPickersCalendarHeader-labelContainer': {
    fontSize: '1rem',
    fontWeight: '700',
  },
  '& .MuiPickersCalendarHeader-root': {
    paddingLeft: '16px',
    paddingRight: '11px',
  },
  '& .MuiPickersDay-root, .MuiTypography-caption': {
    width: '40px',
    height: '40px',
  },
  '& .MuiDayPicker-slideTransition': {
    minHeight: '220px',
  },
};
