import ListItemIcon from '../ListItemIcon';
import { styled } from '@mui/material/styles';
import MenuItemBase from './MenuItemBase';
import { tannerSemantic } from '../ThemeProvider/colors';

export const StyledMenuItemBase = styled(MenuItemBase)<{
  variant?: 'normal' | 'check';
}>(({ variant }) => ({
  ...(variant === 'check' && {
    minWidth: 284,
    border: '2px solid transparent',
    paddingRight: '12px',
    '&.Mui-selected': {
      color: tannerSemantic.info.color,
      backgroundColor: tannerSemantic['info']['background'],
      '&:hover': { backgroundColor: '#CBDEF8' },
    },
    '+.MuiDivider-root': {
      marginTop: '6px',
    },
  }),

  '.MuiCheckbox-root': {
    padding: '0px',
    height: '18px',
    width: '18px',
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)({
  color: 'inherit',
  fontSize: '17px',
  marginRight: '-12px',
  marginLeft: '4px',
});
