import Box, { BoxProps } from '@mui/material/Box';
import { tannerGray } from '../ThemeProvider/colors';
import { styled } from '@mui/material';

export const TitleContainer = styled('div')({
  alignItems: 'flex-start',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: 8,
});

export const Title = styled('div', { name: 'Title' })<{
  sub?: boolean;
  light?: boolean;
  disabled?: boolean;
}>(({ sub, light, disabled }) => ({
  color: sub || light || disabled ? tannerGray['600'] : '#000000',
  fontSize: sub ? 12 : 14,
  alignSelf: light ? 'center' : undefined,
}));

export const AvatarTitleWrap = styled('div', { name: 'AvatarTitleWrap' })<{
  selected?: boolean;
}>(({ selected = false }) => ({
  display: 'flex',
  flex: '1 1 auto',
  opacity: selected ? 0.5 : 1,
}));

export const AvatarContainer = styled('div', { name: 'AvatarContainer' })<{
  disabled?: boolean;
}>(({ disabled }) => ({
  opacity: disabled ? 0.2 : 1,
}));

export const CheckWrap = styled('div', { name: 'CheckWrap' })({
  width: 24,
  textAlign: 'right',
});

interface StyledBoxProps extends BoxProps {
  disabled?: boolean;
}

export const StyledBox = styled(Box)<StyledBoxProps>(
  ({ theme, disabled, ...rest }) => ({
    borderRadius: 3,
    display: 'flex',
    padding: 8,
    width: '100%',
    pointerEvents: disabled ? 'none' : 'auto',
  }),
);
