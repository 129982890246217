import {
  createMyPlugins,
  MyPlatePlugin,
  MyValue,
} from '../typescript/plateTypes';
import {
  createLinkPlugin,
  LinkPlugin,
  PlateFloatingLink,
  RenderAfterEditable,
} from '@udecode/plate';
import { plateUI } from './plateUI';

export const linkPlugin: Partial<MyPlatePlugin<LinkPlugin>> = {
  renderAfterEditable: PlateFloatingLink as RenderAfterEditable<MyValue>,
};

export const myLinkPlugin = createMyPlugins([createLinkPlugin(linkPlugin)], {
  components: plateUI,
});
