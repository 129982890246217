import React from 'react';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { ArrowDown } from '@octanner/prism-icons';
import TextField from '../TextField';
import Chip from '../Chip';
import { FilterOptionsState } from '@mui/material';

type Props<
  Option extends object = any,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = {
  renderOption?: (
    props: any,
    option: Option,
    state: any,
  ) => React.ReactNode | JSX.Element;
  renderTags?: (
    value: Option[],
    getTagProps: Function,
    ownerState: object,
  ) => React.ReactNode | JSX.Element;
  disablePortal?: boolean;
  className?: string;
  autoCompleteProps?: MuiAutocompleteProps<
    Option,
    Multiple,
    DisableClearable,
    FreeSolo
  >;
  label?: string;
  options: Option[];
  multiple?: boolean;
  groupBy?: ((option: Option) => string) | undefined;
  getOptionLabel?: (option: Option) => string;
  getChipOptionLabel?: (option: Option) => string;
  sx?: React.CSSProperties;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: Option,
    reason: any,
  ) => void;
  inputValue?: string;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Option | Option[] | null,
  ) => void;
  classes?: Record<string, string>;
  style?: React.CSSProperties;
  defaultValue?: Option | Option[] | null;
  loading?: boolean;
  getOptionSelected?: (
    option: Option,
    value: Option | Option[] | null,
  ) => boolean;
  required?: boolean;
  renderInput?: (params: any) => React.ReactNode;
  error?: boolean;
  helperText?: string;
  id?: string;
  optionalText?: string;
  noOptionsText?: string;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  fullWidth?: boolean;
  value?: Option | Option[] | string | null | undefined;
  disabled?: boolean;
  limitTags?: number;
  isOptionEqualToValue?: (
    option: Option,
    value: Option | Option[] | null,
  ) => boolean;
  dataTestId?: string;
  autoHighlight?: boolean;
  ListboxProps?: React.HTMLAttributes<HTMLElement>;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  filterOptions?: (
    options: Option[],
    state: FilterOptionsState<Option>,
  ) => Option[];
  filterSelectedOptions?: boolean;
  freeSolo?: boolean;
  clearOnBlur?: boolean;
};

const StyledAutocomplete = styled(MuiAutocomplete)<Props>(
  {
    '& .MuiFilledInput-root': {
      '& .MuiAutocomplete-tag': {
        marginTop: '8px',
      },
      '& .MuiFilledInput-input': {
        paddingLeft: '8px',
      },
      '& .MuiAutocomplete-clearIndicator': {
        visibility: 'visible',
      },
    },
  },
  {
    '& + .MuiAutocomplete-popper': {
      paddingTop: `4px`,
      '& .MuiFilledInput-input': {
        paddingLeft: '8px',
      },
      '& .MuiAutocomplete-paper': {
        fontSize: '14px',
      },
      '.MuiAutocomplete-option': {
        fontSize: '14px',
        paddingLeft: '8px',
        marginLeft: '8px',
        marginRight: '8px',
      },
      '& .MuiAutocomplete-listbox': {
        '& .MuiAutocomplete-option': {
          borderBottom: '0px',
        },
      },
      '.MuiAutocomplete-groupUl': {
        borderRadius: '0px',
        padding: '0px 0px 8px 0px',
      },
      '.MuiListSubheader-root': {
        fontSize: '12px',
        maxHeight: '32px',
        lineHeight: '32px',
      },
      '.MuiAutocomplete-groupLabel': {
        paddingLeft: '16px',
      },
      "& .MuiAutocomplete-option[aria-selected='true']": {
        backgroundColor: '#DEEBFC',
        color: '#0060E0',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '& .Mui-focused': {
          backgroundColor: '#DEEBFC',
        },
      },
      '.MuiAutocomplete-listbox > li': {
        borderBottom: '1px solid #C4C4C4',
        '&:last-child': {
          paddingTop: '8px',
          borderBottom: '0px',
          marginBottom: '8px',
        },
      },
      '.MuiAutocomplete-listbox > li:last-child > ul': {
        '&:last-child': {
          padding: `0px`,
        },
      },
      '.MuiAutocomplete-listbox': {
        padding: '8px 0px 0px 0px',
      },
      ".MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
        backgroundColor: '#DEEBFC',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
);

const Autocomplete: React.FC<Props> = (props) => {
  const {
    multiple = false,
    options,
    label,
    groupBy,
    getOptionLabel,
    renderOption,
    sx,
    onInputChange,
    inputValue,
    onChange,
    classes,
    className = '',
    style,
    defaultValue,
    loading,
    getOptionSelected,
    required,
    error,
    helperText,
    id,
    noOptionsText,
    open,
    onOpen,
    onClose,
    fullWidth,
    renderInput,
    renderTags = (value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          size={'small'}
          label={getChipOptionLabel(option)}
          {...getTagProps({ index })}
        />
      )),
    value,
    disabled,
    limitTags,
    isOptionEqualToValue,
    autoHighlight,
    dataTestId,
    ListboxProps,
    disableCloseOnSelect,
    disableClearable,
    filterOptions,
    filterSelectedOptions,
    getChipOptionLabel = (option) => option.label || '',
    disablePortal = true,
    freeSolo = false,
    clearOnBlur = false,
  } = props;
  return (
    <StyledAutocomplete
      componentsProps={{
        clearIndicator: {
          tabIndex: 0,
        },
        popupIndicator: { tabIndex: 0 },
      }}
      limitTags={limitTags}
      className={className}
      id={id}
      freeSolo={freeSolo}
      clearOnBlur={clearOnBlur}
      renderInput={
        renderInput
          ? renderInput
          : (params) => (
              <TextField
                required={required}
                helperText={helperText}
                error={error}
                label={label}
                {...params}
              />
            )
      }
      options={options}
      groupBy={groupBy}
      disablePortal={disablePortal}
      popupIcon={<ArrowDown />}
      multiple={multiple}
      renderTags={renderTags}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      sx={sx}
      onInputChange={onInputChange}
      inputValue={inputValue}
      onChange={onChange}
      classes={classes}
      style={style}
      defaultValue={defaultValue}
      loading={loading}
      getOptionSelected={getOptionSelected}
      required={required}
      noOptionsText={noOptionsText}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      fullWidth={fullWidth}
      value={value}
      disabled={disabled}
      isOptionEqualToValue={isOptionEqualToValue}
      autoHighlight={autoHighlight}
      ListboxProps={ListboxProps}
      dataTestId={dataTestId}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      filterSelectedOptions={filterSelectedOptions}
    />
  );
};

export default Autocomplete;
export * from '@mui/material/Autocomplete';
