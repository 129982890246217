import React from 'react';
import Grid from '../Grid';
import CardMedia from '../CardMedia';
import MediaCard from './MediaCard';
import { Trash } from '@octanner/prism-icons';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import ScrollLoader from './ScrollLoader';
import RenderSkeletonLoader from './RenderSkeletonLoader';
import {
  ScrollArea,
  StyledBox,
  LoadButton,
  TrashButton,
  StyledVideoPlayer,
  StyledName,
  StyledBackground,
} from './styles';
import { Media, MediaLibraryProps } from './types';

const DEFAULT_PAGE_SIZE = 4;

export const getPreferredMediaUrl = (
  url1: string | undefined,
  url2: string | undefined,
) => {
  return (url1 && !url1.includes('.gif')) || (url2 && url2.includes('.gif'))
    ? url2 || url1
    : url1 || url2;
};

const MediaLibrary: React.FC<MediaLibraryProps> = ({
  loading = false,
  pageSize = DEFAULT_PAGE_SIZE,
  data = [],
  onShowMoreClick,
  onMediaSelect,
  showMore = false,
  size = 'auto',
  'data-testid': testid,
  selectedMedia = null,
  variant = 'button',
}) => {
  const [selected, setSelected] = React.useState<Media | null>(selectedMedia);
  const [isHovered, setIsHovered] = React.useState(false);
  const [mediaLoadError, setMediaLoadError] = React.useState(false);
  const { t } = useTranslation();

  const onLoadMore = () => {
    if (onShowMoreClick) {
      onShowMoreClick();
    }
  };

  const RenderMedia = () =>
    data.map((item: Media) => (
      <Grid key={item?.title} item xs>
        <MediaCard
          key={item?.title}
          setSelected={setSelected}
          onMediaSelect={onMediaSelect}
          size={size}
          media={item}
        />
      </Grid>
    ));

  return (
    <Box data-testid={testid}>
      {!selected && (
        <Box>
          {variant === 'scroll' ? (
            <ScrollArea
              dataLength={data.length}
              loading={loading}
              id="scrollArea"
            >
              <StyledBox size={size}>
                {RenderMedia()}
                {!(data.length > 0) && <RenderSkeletonLoader pageSize={16} />}
              </StyledBox>
              {showMore && (
                <ScrollLoader
                  size={size}
                  pageSize={pageSize}
                  load={onLoadMore}
                />
              )}
            </ScrollArea>
          ) : (
            <StyledBox size={size}>
              {RenderMedia()}
              {loading && <RenderSkeletonLoader pageSize={pageSize} />}
            </StyledBox>
          )}
          {showMore && variant !== 'scroll' && (
            <LoadButton
              data-testid="media-library-show-more"
              loading={loading}
              color={'secondary'}
              onClick={onLoadMore}
            >
              {t('prism-header:show-more', 'Show more')}
            </LoadButton>
          )}
        </Box>
      )}
      {selected && (
        <Box borderRadius={3}
        position="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
          <TrashButton
            isVideo={selected.mediaType === 'video'}
            disableRipple
            disableFocusRipple
            role="button"
            aria-label="remove"
            onClick={() => {
              setSelected(null);
              setMediaLoadError(false);
              if (onMediaSelect) {
                onMediaSelect(null);
              }
            }}
          >
            <Trash />
          </TrashButton>    
          {selected.mediaType === 'img' ? (
            <Box width="100%">
            <CardMedia
              component={selected.mediaType}
              src={
                getPreferredMediaUrl(selected.largeUrl, selected.normalUrl) ||
                selected.thumbNailUrl
              }
              title={selected.title}
              onError={() => setMediaLoadError(true)} 
            />
            {!mediaLoadError && selected?.title && 
            <StyledBackground mediaType={selected.mediaType}>
            <StyledName>{selected.title}</StyledName>
            </StyledBackground>}
            </Box>
          ) : (
            <Box width="100%">
            <StyledVideoPlayer
              posterUrl={
                selected.largeUrl || selected.thumbNailUrl || selected.normalUrl
              }
              videoUrl={selected.mediaUrl}
              onError={() => setMediaLoadError(true)} 
            />
            {!mediaLoadError && !isHovered && selected?.title &&  
            <StyledBackground mediaType={selected.mediaType}>
            <StyledName variant="h4">{selected.title}</StyledName>
            </StyledBackground>}
            </Box>
          )}
            
        </Box>
      )}
    </Box>
  );
};

export default MediaLibrary;
