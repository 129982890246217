import React, { Fragment, useMemo } from 'react';
import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  IconButtonProps,
  Tabs,
  Tab,
  ThemeProvider,
  styled,
  useMediaQuery,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import Menu from '../Menu';
import Autocomplete from '@mui/material/Autocomplete';
import {
  ArrowTriangleDown,
  Close as CloseIcon,
  MenuHamburger as MenuHamburgerIcon,
  Notification as NotificationIcon,
  Search as SearchIcon,
} from '@octanner/prism-icons';
import Avatar from '../Avatar';
import LayoutContext from '../context/Layout';
import useLayoutLinks, {
  MenuLinks,
  PrimaryLinks,
  SecondaryLinks,
} from './useLayoutLinks';
import useTranslations from './useTranslations';
import useHeaderTheme from './useHeaderTheme';
import Skeleton from '@mui/material/Skeleton';
import { Identity, Link, UserBank } from './interfaces';
import CultureCloudWhiteLogo from './CultureCloudWhiteLogo';
import CultureCloudGradientLogo from './CultureCloudGradientLogo';
import { useIdentitySearch } from './identitySearch';
import IdentityInfo from '../IdentityInfo/IdentityInfo';
import ImpersonationBanner from '../ImpersonationBanner';
import useCatalogLink from '../hooks/useCatalogLink';
import useAnalytics from './useAnalytics';
import useIsBellActive from './useIsBellActive';
import { tannerGray } from '../ThemeProvider/colors';
import {
  getFullName,
  useFeatureFlag,
  useMaintenancePage,
} from '@octanner/prism-utilities';
import { useUserSearchConfigFields } from './useUserSearchConfigFields';
import { useGetPointName } from './useGetPointName';
import { useEnabledPreferredPronouns } from './useEnabledPreferredPronouns';
import MenuItemLink from '../MenuItem/MenuItemLink';
import Tooltip from '../Tooltip';
import { DateTime } from 'luxon';
import CategoricMenu from '../CategoricMenu';
import VerticalTab from '../VerticalTab';

const outlineColor = ' #0060E0';

const HeaderContainer = styled('header', { name: 'HeaderContainer' })({
  minHeight: 72,
});

const ProfileMenuItem = styled(MenuItemLink, { name: 'ProfileMenuItem' })({
  fontSize: '14px',
  marginBottom: '8px',
});

const HeaderRow = styled('div', {
  name: 'HeaderRow',
  shouldForwardProp: (prop) => !/secondary|slim/.test(String(prop)),
})<{
  secondary?: boolean;
  slim?: boolean;
}>(({ secondary = false, slim = false }) => ({
  backgroundColor: secondary ? tannerGray[900] : '#fff',
  boxShadow: secondary || slim ? undefined : '0px 1px 0px #C4C4C4',
  color: secondary ? '#fff' : undefined,
  height: secondary ? 56 : 72,
  position: 'relative',
}));

const HeaderInnerRow = styled('div', { name: 'HeaderInnerRow' })<{
  centered?: boolean;
}>(({ centered = false }) => ({
  display: 'flex',
  height: '100%',
  alignItems: centered ? 'center' : undefined,
  justifyContent: centered ? 'center' : 'space-between',
  margin: '0 auto',
  maxWidth: '960px',
  padding: '0 8px',
}));

const HeaderCell = styled('div', { name: 'HeaderCell' })({
  alignItems: 'center',
  display: 'flex',
});

const AutoCompleteHeaderCell = styled(HeaderCell, {
  name: 'AutoCompleteHeaderCell',
  shouldForwardProp: (prop) => !/searchOpen|snipBorder/.test(String(prop)),
})<{
  searchOpen?: boolean;
  snipBorder?: boolean;
}>(({ searchOpen = false, snipBorder = false }) => ({
  alignSelf: 'center',
  borderRadius: 5,
  flexGrow: 0,
  flexShrink: 1,
  marginLeft: 'auto',
  maxWidth: 500,
  transition: 'all 200ms',
  ...(searchOpen && {
    backgroundColor: '#363636',
    flexGrow: 1,
    marginLeft: undefined,
  }),
  ...(snipBorder && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),
}));

const HeaderAvatar = styled(Avatar, { name: 'HeaderAvatar' })({
  marginLeft: 8,
});

const Logo = styled('div', { name: 'Logo' })({
  display: 'flex',
  padding: '0 96px 0 16px',
});

const LogoImage = styled('img', { name: 'LogoImage' })({
  width: '100%',
});

const CancelButton = styled(Button, { name: 'CancelButton' })({
  color: '#ABABAE',
  fontWeight: 500,
});

const buttonFocusOverride = {
  '&:focus-visible': {
    backgroundColor: tannerGray['100'],
    outline: `3px solid ${outlineColor}`,
    outlineOffset: '3px',
    borderRadius: '4px',
  },
};
const tabFocusOverride = {
  '&:focus-visible': {
    outline: `3px solid ${outlineColor}`,
    outlineOffset: '-8px',
    borderRadius: '14px',
  },
};

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledLogoLink = styled('a')(() => ({
  height: '40px',
}));

const StyledLogoImage = styled(LogoImage)(() => ({
  height: '40px',
  maxWidth: '220px',
  objectFit: 'contain',
}));

const StyledCultureCloudLogoLink = styled('a')(() => ({
  paddingTop: '8px',
  '&:focus-visible': {
    outline: `3px solid ${outlineColor}`,
    outlineOffset: '4px',
    borderRadius: '2px',
  },
}));

const SecondaryListItemText = styled(ListItemText)(({ theme }) => ({
  textAlign: 'right',
}));

interface ContainerCellProps {
  searchOpen: boolean;
}

interface MultipleBankAndAccessCodes {
  category: string;
  primary: string;
  secondary: string;
  url?: string;
}

const ContainerCell = styled(HeaderCell)<ContainerCellProps>(
  ({ theme, searchOpen }) => ({
    maxWidth: 800,
    width: searchOpen ? 0 : '100%',
  }),
);

const LogoCell = styled(HeaderCell)(({ theme }) => ({
  paddingRight: 40,
}));

const NotificationBell: React.FC<IconButtonProps & { isActive?: boolean }> = ({
  isActive = false,
  ...props
}) => (
  <Tooltip title="Notification" placement="bottom">
    {/* 
    // @ts-ignore */}
    <IconButton
      {...props}
      data-testid="notification-icon"
      component="a"
      href="/notifications"
      aria-label={!isActive ? 'Notifications' : `New notifications`}
      sx={{
        '&:focus-visible': {
          outline: `3px solid ${outlineColor}`,
          outlineOffset: '2px',
          backgroundColor: tannerGray['900'],
        },
      }}
    >
      <Badge invisible={!isActive} variant="dot" color="error">
        <NotificationIcon />
      </Badge>
    </IconButton>
  </Tooltip>
);

const MenuAvatarText = styled('div', { name: 'MenuAvatarText' })({
  fontSize: 14,
  fontWeight: 700,
  marginLeft: 8,
});

const testRoute = (url: string) => (route: string) =>
  route.length > 1 ? url.startsWith(route) : url === route;

const newTabProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export type HeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  fullStoryOrg: string;
  isImpersonating: boolean;
  specialPointName?: string;
  onStopImpersonation(): void;
  routerPath?: string;
  routerPush?: (url: string) => void;
  routerRoutes?: string[];
  referrerUrl: string;
  variant: string;
  logoutUrl?: string;
};

const Header: React.FC<HeaderProps> = ({
  fullStoryOrg,
  isImpersonating,
  specialPointName,
  onStopImpersonation,
  routerPath,
  routerPush,
  routerRoutes = [],
  referrerUrl,
  variant = 'normal',
  logoutUrl,
  ...props
}) => {
  const headerTheme = useHeaderTheme();
  const isMobile = useMediaQuery('@media (max-width: 768px)');
  const [currentIdentityQuery, layoutQuery, localeQuery] =
    React.useContext(LayoutContext);
  const { t, lang } = useTranslations(
    currentIdentityQuery?.data?.currentIdentity,
    localeQuery?.data?.customerCoreLocaleMapsByCustomer?.nodes,
  );
  const currentIdentity = currentIdentityQuery?.data?.currentIdentity;
  const customerUuid = currentIdentity?.customer?.customerUuid;
  const identityUuid = currentIdentity?.identityUuid;
  const links = useLayoutLinks(layoutQuery?.data);
  const [activeLink, setActiveLink] = React.useState<string | false>(false);
  const [navOpen, setNavOpen] = React.useState(false);
  const searchRef = React.useRef<HTMLDivElement | null>(null);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const { data: vibePointInfo, loading: vibePointLoading } = useGetPointName(
    customerUuid,
    identityUuid,
    lang,
  );
  const specialPoint =
    vibePointInfo?.vibeGetDerivedVibeDetails?.attributes?.text
      ?.pointsNamePlural;
  const identitySearchQuery = useIdentitySearch(searchText);
  const { data: enablePronounData } = useEnabledPreferredPronouns(customerUuid);
  const enablePronouns = enablePronounData?.ccWebPreferredPronouns?.enabled;
  const userSearchConfig = useUserSearchConfigFields(customerUuid);
  const searchFields =
    userSearchConfig?.data?.ccWebUserSearchConfigEnabledFields?.fields;
  const [profileMenuEl, setProfileMenuEl] =
    React.useState<HTMLButtonElement | null>(null);
  const bankUrl = useCatalogLink({ referrerUrl, lang });
  const featureFlag = useFeatureFlag<boolean>('ccui-multiple-banks-p-240315');
  const multipleBanksFeatureFlag = useMemo(
    () =>
      featureFlag && featureFlag[0] === true && featureFlag[1] === true
        ? true
        : false,
    [featureFlag],
  );

  const getBankUrl = (bank: UserBank) => {
    if (!currentIdentity) return '';
    const queryParams = new URLSearchParams({
      countryCode:
        currentIdentity.profile?.catalogCoreCountry?.iso2Code ||
        currentIdentity.profile?.homeCoreCountry?.iso2Code ||
        '',
      programId: bank?.programId?.toString() || '',
      refererUrl: referrerUrl,
      locale: (lang || navigator.languages?.[0] || navigator.language).replace(
        '-',
        '_',
      ),
    });
    return `/catalog?${queryParams}`;
  };
  const layoutLoading = !layoutQuery?.data && layoutQuery?.loading;
  const layout = layoutQuery?.data;
  const headerView = variant === 'normal' ? true : false;
  const currentIdentityLoading =
    !currentIdentityQuery?.data && currentIdentityQuery?.loading;

  const hasRedeemPermission =
    layout?.hasRedeemAwardsPermission?.hasRedeemAwardsPermission;
  const bank =
    layout?.bankBalance?.find((bank) => bank.defaultBank) ||
    layout?.bankBalance?.[0];

  const multipleBankOrder = [
    t('prism-header:bank-accounts', 'Bank Accounts'),
    t('prism-header:access-codes', 'Access Codes'),
  ];
  const hasMultipleBank =
    layout?.bankBalance &&
    layout.bankBalance.length > 1 &&
    multipleBanksFeatureFlag;

  const multipleBanks =
    layout?.bankBalance &&
    layout.bankBalance.map((bank) => {
      const accessCodes =
        bank.accessCodeDetails &&
        bank.accessCodeDetails.map((access) => {
          const dateTime = DateTime.fromFormat(
            access.expiresAt,
            'yyyy-MM-dd hh:mm:ss.S',
          );
          return {
            category: t('prism-header:access-codes', 'Access Codes'),
            primary: access.accessCode,
            secondary: t(
              'prism-header:expiring-access-codes',
              'Expires {accessCodeDate}',
              {
                accessCodeDate: dateTime.toLocaleString(DateTime.DATE_SHORT),
              },
            ),
            url: getBankUrl(bank),
          };
        });
      return {
        category: t('prism-header:bank-accounts', 'Bank Accounts'),
        primary: bank.bankName,
        secondary: bank.points?.toString(),
        url: getBankUrl(bank),
        accessCodes,
        defaultBank: bank.defaultBank,
      };
    });
  // sort multiple banks by default bank
  multipleBanks?.sort((a, b) => {
    if (a.defaultBank) return -1;
    if (b.defaultBank) return 1;
    return 0;
  });
  const banksWithAccessCodes = multipleBanks?.filter(
    (o) => o.accessCodes && o.accessCodes.length > 0,
  );
  const accessCodes = banksWithAccessCodes
    ?.map((bank) => {
      return bank.accessCodes || [];
    })
    .flat() as MultipleBankAndAccessCodes[];

  // sort access codes by expiration date
  accessCodes?.sort((a, b) => {
    const dateA = a.secondary.split(' ')[1];
    const dateB = b.secondary.split(' ')[1];
    return (
      new Date(dateA).getTime() - new Date(dateB).getTime() ||
      a.secondary.localeCompare(b.secondary)
    );
  });

  const vettedMultipleBanks = multipleBanks?.map((bank) => {
    return {
      category: bank.category,
      primary: bank.primary,
      secondary: bank.secondary,
      url: bank.url,
    };
  }) as MultipleBankAndAccessCodes[];

  const menuData = vettedMultipleBanks?.concat(accessCodes);

  const { isActive } = useIsBellActive();

  useMaintenancePage();

  useAnalytics({
    currentIdentity,
    fullStoryOrg,
    links,
    bank,
  });

  React.useEffect(() => {
    if (searchOpen && searchRef.current) {
      searchRef.current.querySelector('input')?.focus();
    }
  }, [searchRef, searchOpen]);

  React.useEffect(() => {
    const pathname = routerPath || window.location.pathname;
    const isActiveTab = (link: Link) =>
      link.url.length > 1
        ? pathname.startsWith(link.url)
        : pathname === link.url;
    const primaryLink = links.primaryLinks.find(isActiveTab);

    setActiveLink(primaryLink?.url || false);
  }, [links.primaryLinks, routerPath]);

  const handleNavigation = (url: string) => (e: React.MouseEvent) => {
    if (routerPush && routerRoutes.some(testRoute(url))) {
      e.preventDefault();
      routerPush(url);
    }
    setNavOpen(false);
    setProfileMenuEl(null);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.target.value);

  const selectIdentity = (_: any, identity: Identity | string) => {
    if (typeof identity !== 'string') {
      const url = `/profile/${identity.identityUuid}`;
      if (routerPush && routerRoutes.some(testRoute(url))) {
        routerPush(url);
      } else {
        window.location.assign(url);
      }
    }
    setSearchOpen(false);
    setNavOpen(false);
    setSearchText('');
  };

  const fullName = getFullName(currentIdentity, enablePronouns);

  const getCorrectPointName = (bank: UserBank) => {
    let correctName = '';
    if (specialPoint) correctName = specialPoint;
    else if (specialPointName) {
      correctName = specialPointName;
    } else {
      correctName = t('prism-header:points', 'Points');
    }
    const totalPoints =
      typeof bank?.points === 'number' ? bank?.points.toLocaleString() : 0;

    return `${totalPoints} ${correctName}`;
  };

  const getCorrectURL = (id: string, url: string, logOut?: string) =>
    id === MenuLinks.Logout ? logoutUrl ?? '/logout' : url;

  const isCurrentPage = (link: string) => window.location.pathname === link;

  const renderMobileLinks = links.menuLinks.filter(
    (link) => link.id !== MenuLinks.Logout,
  );

  return (
    <ThemeProvider theme={headerTheme}>
      {currentIdentity && isImpersonating && (
        <ImpersonationBanner
          identity={currentIdentity}
          onStopImpersonation={onStopImpersonation}
        />
      )}
      <HeaderContainer {...props}>
        {isMobile && (
          <Drawer open={navOpen} onClose={() => setNavOpen(false)}>
            <List>
              <ListItem style={{ justifyContent: 'space-between' }}>
                <CultureCloudGradientLogo
                  data-testid="culturecloud-logo"
                  aria-label="Culture Cloud Logo"
                />
                <IconButton
                  onClick={() => setNavOpen(false)}
                  aria-label="close menu"
                >
                  <CloseIcon />
                </IconButton>
              </ListItem>
              <ListItem>
                <Autocomplete<Identity, false, true, true>
                  fullWidth
                  onChange={selectIdentity}
                  options={identitySearchQuery.data || []}
                  filterOptions={(option) => option}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <IdentityInfo
                        identity={option}
                        color="primary"
                        isMobile={isMobile}
                        customSearchFields={searchFields}
                        pronounsEnabled={enablePronouns}
                      />
                    </li>
                  )}
                  disableClearable
                  freeSolo
                  onBlur={() => {
                    if (!searchText) setSearchOpen(false);
                  }}
                  ref={searchRef}
                  inputValue={searchText}
                  renderInput={(params) => (
                    <TextField
                      data-testid="search-cc-header"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ marginLeft: '6px' }}
                          >
                            <SearchIcon data-testid="search-icon" />
                          </InputAdornment>
                        ),
                        endAdornment: searchText ? (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="cancel search"
                              onClick={() => {
                                setSearchOpen(false);
                                setSearchText('');
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : undefined,
                        onChange: onSearch,
                      }}
                      placeholder={t('prism-header:search-people')}
                      fullWidth
                    />
                  )}
                />
              </ListItem>
              {renderMobileLinks.map(({ id, translationString, url }) =>
                id === MenuLinks.Profile ? (
                  <React.Fragment key={id}>
                    <ListItem
                      data-testid={`${translationString}-link`}
                      button
                      component="a"
                      href={url}
                      onClick={handleNavigation(url)}
                    >
                      <Avatar
                        src={currentIdentity?.profile?.avatar}
                        size="sm"
                      />
                      <MenuAvatarText>{fullName}</MenuAvatarText>
                    </ListItem>
                    {hasRedeemPermission && bank && hasMultipleBank ? (
                      <>
                        <VerticalTab
                          data-testid="multiple-banks-menu-button"
                          label={t('prism-header:store')}
                        >
                          {vettedMultipleBanks.map(
                            ({ url, primary, secondary }) => (
                              <ListItem
                                data-testid={`multiple-banks-menu-${primary}`}
                                button
                                key={primary}
                                className="fs-unmask"
                                component="a"
                                href={url}
                                onClick={handleNavigation(url!)}
                              >
                                <ListItemText primary={primary} />
                                <SecondaryListItemText primary={secondary} />
                              </ListItem>
                            ),
                          )}
                          {accessCodes && accessCodes.length > 0 && (
                            <VerticalTab
                              data-testid="access-codes"
                              label={t('prism-header:access-codes')}
                            >
                              {accessCodes.map(
                                ({ url, primary, secondary }) => (
                                  <ListItem
                                    data-testid={`multiple-banks-menu-${primary}`}
                                    button
                                    key={primary}
                                    className="fs-unmask"
                                    component="a"
                                    href={url}
                                    onClick={handleNavigation(url!)}
                                    style={{ paddingLeft: '8px' }}
                                  >
                                    <ListItemText primary={primary} />
                                    <SecondaryListItemText
                                      primary={secondary}
                                      primaryTypographyProps={{
                                        fontSize: '12px',
                                        color: tannerGray['600'],
                                      }}
                                    />
                                  </ListItem>
                                ),
                              )}
                            </VerticalTab>
                          )}
                        </VerticalTab>
                      </>
                    ) : (
                      <ListItem
                        data-testid="store-link"
                        button
                        className="fs-unmask"
                        component="a"
                        href={bankUrl}
                        onClick={handleNavigation(bankUrl)}
                      >
                        <ListItemText aria-label="Store. Shop Now">
                          {t('prism-header:store')}
                        </ListItemText>
                      </ListItem>
                    )}
                  </React.Fragment>
                ) : (
                  <ListItem
                    data-testid={`${translationString}-link`}
                    key={id}
                    button
                    className="fs-unmask"
                    component="a"
                    target={'_self'}
                    href={url}
                    onClick={handleNavigation(url)}
                  >
                    <ListItemText>
                      {t(`prism-header:${translationString}`)}
                    </ListItemText>
                  </ListItem>
                ),
              )}
              {links.secondaryLinks.map(({ id, translationString, url }) => (
                <ListItem
                  data-testid="admin-link"
                  key={id}
                  button
                  className="fs-unmask"
                  component="a"
                  onClick={handleNavigation(url)}
                  href={url}
                  {...(id === SecondaryLinks.Admin && newTabProps)}
                >
                  <ListItemText>
                    {t(`prism-header:${translationString}`)}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              {links.primaryLinks.map(({ id, translationString, url }) => (
                <ListItem
                  data-testid={`${translationString}-link`}
                  key={id}
                  button
                  className="fs-unmask"
                  component="a"
                  onClick={handleNavigation(url)}
                  href={url}
                  {...(id === PrimaryLinks.Wellbeing && newTabProps)}
                >
                  <ListItemText>
                    {t(`prism-header:${translationString}`)}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              <ListItem
                button
                className="fs-unmask"
                component="a"
                href={logoutUrl ?? '/logout'}
                data-testid="logout-link"
                onClick={handleNavigation(logoutUrl ?? '/logout')}
              >
                <ListItemText>{t('prism-header:logout')}</ListItemText>
              </ListItem>
            </List>
            {layout?.theme?.logo?.value && (
              <Logo>
                <LogoImage src={layout?.theme?.logo?.value} />
              </Logo>
            )}
          </Drawer>
        )}
        <HeaderRow slim={!headerView}>
          <HeaderInnerRow>
            {isMobile && (
              <HeaderCell>
                <IconButton
                  onClick={() => setNavOpen(true)}
                  aria-label="open menu"
                >
                  <MenuHamburgerIcon />
                </IconButton>
              </HeaderCell>
            )}

            <HeaderCell>
              {!layout?.theme?.logo?.value ? (
                <StyledSkeleton height={24} width={80} />
              ) : (
                <StyledLogoLink
                  href="/"
                  onClick={handleNavigation('/')}
                  aria-label={`${
                    currentIdentity?.customer?.name || 'Company'
                  } logo. ${
                    isCurrentPage('/') ? 'Same Page' : 'Go to Home Page'
                  }`}
                >
                  <StyledLogoImage
                    src={layout?.theme?.logo?.value}
                    alt={`${currentIdentity?.customer?.name || 'Company'} logo`}
                  />
                </StyledLogoLink>
              )}
            </HeaderCell>

            {isMobile && (
              <HeaderCell>
                <NotificationBell
                  isActive={isActive}
                  onClick={handleNavigation('/notifications')}
                />
              </HeaderCell>
            )}

            {!isMobile && (
              <HeaderCell>
                {layoutLoading ? (
                  <Skeleton
                    height={24}
                    width={80}
                    style={{ marginRight: 16 }}
                  />
                ) : (
                  <>
                    {headerView &&
                      links.secondaryLinks.map(
                        ({ id, translationString, url }) => (
                          <Button
                            key={id}
                            disableRipple
                            sx={buttonFocusOverride}
                            className="fs-unmask"
                            component="a"
                            variant="text"
                            data-testid={`${translationString}-link`}
                            href={url}
                            onClick={handleNavigation(url)}
                            {...(id === SecondaryLinks.Admin && newTabProps)}
                          >
                            {t(`prism-header:${translationString}`)}
                          </Button>
                        ),
                      )}
                    {vibePointLoading ? (
                      <Skeleton height={24} width={80} style={{ margin: 8 }} />
                    ) : (
                      hasRedeemPermission &&
                      bank &&
                      headerView &&
                      (hasMultipleBank ? (
                        <>
                          <div id="multiple-banks-menu">
                            <CategoricMenu
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              buttonText={
                                <>
                                  <div style={{ marginRight: 8 }}>
                                    {t('prism-header:store')}
                                    <Box
                                      component="span"
                                      style={{ fontWeight: 500 }}
                                    >
                                      |{getCorrectPointName(bank)}
                                    </Box>
                                  </div>
                                  <ArrowTriangleDown />
                                </>
                              }
                              buttonVariant="text"
                              buttonSx={buttonFocusOverride}
                              data-testid="multiple-banks-menu"
                              handleNavigationClick={handleNavigation}
                              options={menuData}
                              sortOrder={multipleBankOrder}
                              sx={{
                                minWidth: '400px',
                                maxHeight: '360px',
                                padding: '0px 8px 8px',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <Button
                          variant="text"
                          component="a"
                          disableRipple
                          sx={buttonFocusOverride}
                          data-testid="store-link"
                          href={bankUrl}
                          onClick={handleNavigation(bankUrl)}
                          target="_blank"
                        >
                          {t('prism-header:store')}
                          <Box component="span" sx={{ fontWeight: 500 }}>
                            |{getCorrectPointName(bank!)}
                          </Box>
                        </Button>
                      ))
                    )}
                    {}
                  </>
                )}
                {currentIdentityLoading ? (
                  <>
                    <Skeleton
                      height={24}
                      width={80}
                      style={{ marginRight: 8 }}
                    />
                    <Skeleton variant="circular" height={32} width={32} />
                  </>
                ) : (
                  <>
                    <Button
                      disableRipple
                      sx={buttonFocusOverride}
                      variant="text"
                      data-testid="current-user-name-header"
                      aria-label={`${fullName} Profile`}
                      aria-haspopup="true"
                      aria-controls="profile-menu"
                      aria-expanded={!!profileMenuEl}
                      onClick={(e) =>
                        setProfileMenuEl(e.currentTarget as HTMLButtonElement)
                      }
                    >
                      {fullName}
                      <HeaderAvatar
                        src={currentIdentity?.profile?.avatar}
                        alt={fullName}
                        data-testid="current-user-avatar-header"
                        size="sm"
                      />
                    </Button>
                    <div id="profile-menu">
                      <Menu
                        id="profile-menu"
                        anchorEl={profileMenuEl}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'bottom',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={!!profileMenuEl}
                        onClose={() => setProfileMenuEl(null)}
                        MenuListProps={{ 'aria-labelledby': 'profile-menu' }}
                        PaperProps={{
                          style: { minWidth: 200, padding: '0 8px' },
                        }}
                      >
                        {links.menuLinks.map(
                          ({ id, translationString, url }) => (
                            <Fragment key={id}>
                              {id === MenuLinks.Logout && (
                                <Divider aria-hidden="true" />
                              )}
                              <ProfileMenuItem
                                href={getCorrectURL(id, url, logoutUrl)}
                                key={id}
                                sx={
                                  id === MenuLinks.Logout
                                    ? {
                                        marginTop: '8px',
                                        marginBottom: '0px',
                                      }
                                    : {}
                                }
                                data-testid={`${translationString}-link`}
                                aria-label={`${translationString}-link`}
                                aria-required="true"
                                onClick={handleNavigation(
                                  getCorrectURL(id, url, logoutUrl),
                                )}
                                autoFocus={id === MenuLinks.Profile}
                              >
                                {t(`prism-header:${translationString}`)}
                              </ProfileMenuItem>
                            </Fragment>
                          ),
                        )}
                      </Menu>
                    </div>
                  </>
                )}
              </HeaderCell>
            )}
          </HeaderInnerRow>
        </HeaderRow>

        {!isMobile && headerView && (
          <HeaderRow secondary>
            <HeaderInnerRow>
              <ContainerCell searchOpen={searchOpen}>
                <LogoCell>
                  <StyledCultureCloudLogoLink
                    href="/"
                    onClick={handleNavigation('/')}
                    aria-label={`Culture Cloud logo. ${
                      isCurrentPage('/') ? 'Same Page' : 'Go to Home Page'
                    }`}
                  >
                    <CultureCloudWhiteLogo data-testid="culturecloud-logo" />
                  </StyledCultureCloudLogoLink>
                </LogoCell>
                <Tabs value={activeLink}>
                  {layoutLoading &&
                    Array(3)
                      .fill('')
                      .map((_, i) => (
                        <Tab
                          key={i}
                          aria-label="loading"
                          label={
                            <Skeleton color="#fff" height={24} width={50} />
                          }
                        />
                      ))}
                  {links.primaryLinks.map(({ id, translationString, url }) => (
                    <Tab
                      key={id}
                      value={url}
                      className="fs-unmask"
                      component="a"
                      sx={tabFocusOverride}
                      disableRipple
                      href={url}
                      data-testid={`${translationString}-link`}
                      onClick={handleNavigation(url)}
                      label={t(`prism-header:${translationString}`)}
                      {...(id === PrimaryLinks.Wellbeing && newTabProps)}
                    />
                  ))}
                </Tabs>
              </ContainerCell>
              <AutoCompleteHeaderCell
                searchOpen={searchOpen}
                snipBorder={(identitySearchQuery.data?.length || 0) > 0}
              >
                <Autocomplete<Identity, false, true, true>
                  fullWidth
                  open={searchOpen}
                  onChange={selectIdentity}
                  options={identitySearchQuery.data || []}
                  filterOptions={(option) => option}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <IdentityInfo
                        identity={option}
                        color="secondary"
                        customSearchFields={searchFields}
                        pronounsEnabled={enablePronouns}
                      />
                    </li>
                  )}
                  disableClearable
                  freeSolo
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: `${searchOpen ? '' : 'none'}`,
                    },
                  }}
                  onFocus={() => setSearchOpen(true)}
                  onBlur={() => {
                    setSearchText('');
                    setSearchOpen(false);
                  }}
                  ref={searchRef}
                  inputValue={searchText}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        inputProps: {
                          ...params.inputProps,
                          style: {
                            maxWidth: searchOpen ? '100%' : '0%',
                          },
                          disabled: !searchOpen,
                          'aria-hidden': !searchOpen,
                          'aria-disabled': !searchOpen,
                        },
                        startAdornment: identitySearchQuery.loading ? (
                          <IconButton
                            color="secondary"
                            aria-label="loading"
                            data-testid="search-loading"
                            disabled={searchOpen}
                            onClick={() => setSearchOpen(true)}
                          >
                            <CircularProgress
                              color="secondary"
                              size="16px"
                              aria-label="loading"
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            color="secondary"
                            aria-label={t(
                              'prism-header:search-people',
                              'Search people',
                            )}
                            disabled={searchOpen}
                            onClick={() => setSearchOpen(true)}
                          >
                            <SearchIcon
                              data-testid="search-icon"
                              style={{ color: searchOpen ? '#ababae' : '' }}
                            />
                          </IconButton>
                        ),
                        endAdornment: searchText ? (
                          <InputAdornment position="end">
                            <CancelButton
                              variant="text"
                              aria-label="cancel search"
                              onClick={() => {
                                setSearchText('');
                                setSearchOpen(false);
                              }}
                            >
                              {t('prism-header:cancel', 'Cancel')}
                            </CancelButton>
                          </InputAdornment>
                        ) : undefined,
                        onChange: onSearch,
                      }}
                      placeholder={t(
                        'prism-header:search-people',
                        'Search People',
                      )}
                      data-testid="search-cc-header"
                      fullWidth
                    />
                  )}
                />
              </AutoCompleteHeaderCell>

              <HeaderCell>
                <NotificationBell
                  color="secondary"
                  isActive={isActive}
                  data-testid="notification-icon"
                  onClick={handleNavigation('/notifications')}
                />
              </HeaderCell>
            </HeaderInnerRow>
          </HeaderRow>
        )}
      </HeaderContainer>
    </ThemeProvider>
  );
};

export default Header;
