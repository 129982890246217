import React from 'react';
import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Search } from '@octanner/prism-icons';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '../CircularProgress';
import PsmButton from '../Button';
import ListItemText from '../ListItemText';

interface TypeaheadSearchProps<OptionType> {
  'data-testid'?: string;
  getOptionLabel?: (option: OptionType) => string;
  /** translated string to place on input */
  inputLabel: string;
  id?: string;
  loading: boolean;
  onChange(option: OptionType): void;
  onSearch(searchText: string): void;
  options?: OptionType[];
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: OptionType,
    state: any,
  ) => React.ReactElement;
  style?: React.CSSProperties;
  adminColor?: boolean;
}

const Button = styled(PsmButton)({
  '&.MuiButton-root': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minWidth: 'auto',
    paddingLeft: 12,
    paddingRight: 12,
  },
});

const FlexContainer = styled('div', { name: 'FlexContainer' })({
  display: 'flex',
  width: '100%',
});

export function TypeaheadSearch<OptionType>(
  props: TypeaheadSearchProps<OptionType>,
) {
  const {
    'data-testid': dataTestId,
    getOptionLabel,
    inputLabel,
    loading,
    onChange,
    onSearch,
    options = [],
    renderOption,
    style,
    adminColor,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const allowOpen = options.length > 0 || searchText;

  return (
    <FlexContainer style={style}>
      <Autocomplete<OptionType, undefined, true>
        {...rest}
        data-testid={dataTestId}
        open={allowOpen ? open : false}
        onChange={(e: any, option: OptionType | null) => {
          if (option) {
            onChange(option);
          }
          setSearchText('');
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableClearable
        filterOptions={(option) => option}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        options={options}
        noOptionsText={<ListItemText primary={t('we-couldnt-find-anyone')} />}
        loading={loading}
        inputValue={searchText}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size="small"
            placeholder={inputLabel}
            onChange={(e) => {
              if (e.target.value.length > 2) {
                setOpen(true);
              } else {
                setOpen(false);
              }
              setSearchText(e.target.value);
              onSearch(e.target.value);
            }}
            inputProps={{
              ...params.inputProps,
              'aria-label': inputLabel,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: loading ? <CircularProgress size={24} /> : null,
            }}
          />
        )}
      />
      <Button aria-label="search-btn" color={adminColor ? 'blue' : 'primary'}>
        <Search width="20" fill="#fff" />
      </Button>
    </FlexContainer>
  );
}

TypeaheadSearch.displayName = 'TypeaheadSearch';

export default TypeaheadSearch;
